import fuzzyMatch from './fuzzy_match';

$.fn.select2.defaults.set('theme', 'bootstrap');

$.fn.select2.defaults.set('sorter', (data) => {
  return data.sort((a, b) => b.score - a.score);
});

$.fn.select2.defaults.set('matcher', (params, data) => {
  if (params.term && data.text) {
    let matches, score, _;
    [matches, score, _] = fuzzyMatch(data.text, params.term);

    if (matches){
      data.score = score;
      return data;
    } else {
      return null;
    }
  } else {
    return data;
  }
});

export const getElementDropdownParent = (el, dropdownParent) => {
  let parent;
  const form = $(el).closest('form')[0];

  if (dropdownParent) {
    parent = dropdownParent.contains(form) ? form : dropdownParent;
  } else {
    parent = form;
  }

  return parent || $('body');
}

const initSelect2 = (element, dropdownParent) => {
  let defaultOptions = { width: '100%' }

  $(function() {
    $(element).find(".make-select2").each((_index, el) => {
      $(el).select2({ ...defaultOptions, dropdownParent: getElementDropdownParent(el, dropdownParent) });
    });

    $(element).find(".make-select2-min-2").each((_index, el) => {
      $(el).select2({ ...defaultOptions, dropdownParent: getElementDropdownParent(el, dropdownParent), minimumInputLength: 2 });
    });

    $(element).find(".make-select2-template-options").each((_index, el) => {
      $(el).select2({
        ...defaultOptions,
        dropdownParent: getElementDropdownParent(el, dropdownParent),
        templateResult: (state) => {
          const template = $($(state.element).data('template-result'))

          return state.id && template.length ? template : state.text
        },
        templateSelection: (state) => {
          const template = $($(state.element).data('template-selection'))

          return state.id && template.length ? template : state.text
        }
      });
    });
  });
}

const removeSelect2ElementsBeforeCachingToPreventDoubleRender = () => {
  $(".select2-hidden-accessible").select2('destroy')
}

$(document).on('show.bs.modal turbo:frame-load turbo:after-stream-render', (e) => initSelect2(e.target, e.target))
$(document).on('turbo:load popover:load cocoon:after-insert', () => initSelect2(document))
$(document).on('select2:init', (e) => initSelect2(e.target))
$(document).on("turbo:before-cache", () => removeSelect2ElementsBeforeCachingToPreventDoubleRender())
