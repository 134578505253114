class GenerateName {
  call(nameHeader) {
    const country_code = $('#input_set_storefront_id  :selected').data('country-code')
    const source = $('#input_set_source_id :selected').text()
    const feature = $('#input_set_feature').val()
    const locale = $('#input_set_locale_id :selected').data('code')
    const data_from = $('#input_set_data_from').val()
    const sampleSize = $('#input_set_sample_sample_size').val()

    if (sampleSize) {
      let originalName = $('#generated-name').data('original-name')
      nameHeader.text([originalName, sampleSize].join(' '))
    } else {
      nameHeader.text([country_code, source, feature, locale, data_from].join(' '))
    }
  }
}

$(document).on('turbo:load', function() {
  const nameHeader = $('#generated-name')

  if (nameHeader.length > 0) {
    new GenerateName().call(nameHeader)

    $('.datetime-picker-input').on('change.td', function(_e) {
      new GenerateName().call(nameHeader)
    })

    $('.name-attr').on('change', function(_e) {
      new GenerateName().call(nameHeader)
    })
  }
});

export default GenerateName
