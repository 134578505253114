function makeSortable() {
  $(".edit-tabular-report .sortable").sortable({
    axis: "y",
    handle: ".drag-handle",
    items: "li:not(.add-button)",
    stop: (_, ui) => setPositionFieldValues(ui.item.closest(".sortable"))
  });
}

function setPositionFieldValues(sortable) {
  sortable.find(".position-field").each((i, input) => input.value = i);
}

$(document).on("turbo:load", makeSortable);
