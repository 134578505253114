class TextRedaction {
  constructor(element) {
    this.$element = $(element)
  }

  create() {
    this.$element.one('click', () => this.$element.addClass('show'))

    return this
  }
}

$(document).on('turbo:load', () => {
  $('body:not(.tasks) [data-capabilities~="text-redaction"] [data-text-redaction]')
    .each((_, el) => new TextRedaction(el).create())
})

export default TextRedaction
