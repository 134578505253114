import { Controller } from "@hotwired/stimulus"
import Clipboard from 'clipboard'

export default class extends Controller {
  static values = { text: String }

  connect() {
    const clipboard = new Clipboard(this.element, { text: (_) => this.textValue })

    $(this.element).tooltip({ placement: 'top' })
    clipboard.on('success', (e) => this.#showTooltip(e.trigger, 'Copied'))
    clipboard.on('error', (e) => this.#showTooltip(e.trigger, 'Error copying'))
  }

  #showTooltip(el, message) {
    $(el)
      .tooltip('hide')
      .attr('data-bs-original-title', message)
      .tooltip('show')

    setTimeout(() => $(el).tooltip('hide'), 1000)
  }
}
