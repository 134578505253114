class GuidelinesMarkdown {
  static defaultOptions = {
    inputElement: '[data-guidelines-markdown-field="input"]',
    previewElement: '[data-guidelines-markdown-field="preview"]'
  }

  constructor (element, options) {
    this.$element = $(element)
    this.options = { ...this.constructor.defaultOptions, ...options }
  }

  create () {
    this._refresh()
    this.$element.find(this.options.inputElement).on('keyup', this._onInputKeyUp.bind(this))

    return this
  }

  _refresh () {
    $.ajax({
      url: this.options.url,
      method: 'post',
      data: this.$element.find(this.options.inputElement).serialize(),
      success: (html) => {
        this.$element.find(this.options.previewElement).html(html)
      }
    })
  }

  _onInputKeyUp (_event) {
    setTimeout(() => this._refresh(), 1000)
  }
}

$(document).on('turbo:load', () => {
  const dataAttrName = 'guidelines-markdown'
  const $guidelinesMarkdownList = $(`[data-${dataAttrName}]`)

  $.map($guidelinesMarkdownList, (el) => {
    const options = $(el).data(dataAttrName)

    return (new GuidelinesMarkdown(el, options)).create()
  })
})

export default GuidelinesMarkdown
