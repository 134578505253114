import Component from '../../utils/store/lib/component.js';

const EXPERIMENT_SELECTOR = '#prune-experiment-selector';

export default class ExperimentSelector extends Component {
  initialize() {
    this.$experimentSelect = $(EXPERIMENT_SELECTOR);

    this.$experimentSelect.on('change', (e) => {
      this.setExperiment($(e.target).val());
    });

    if (this.$experimentSelect.val() != '') {
      this.setExperiment(this.$experimentSelect.val());
    }
  }

  setExperiment(id) {
    this.store.events.publish('set-experiment-id', id);
    this.store.state.experiment_id = id;
  }
}
