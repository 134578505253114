function getAuditPriorityId(el) {
  return $(el).data('priority-id');
}

function getPriority(el) {
  return $(el).data('priority');
}

function multiSortableTable($el) {
  let placeholderHeight = null;
  let clones = null;

  $el.sortable('option', 'helper', (e, item) => {
    const prev = $(item.prevAll('.ui-selected').toArray().reverse());
    const next = item.nextAll('.ui-selected');
    clones = { prev: prev.clone(), next: next.clone() }

    placeholderHeight = item.outerHeight() * (prev.length + next.length + item.length + 1);

    prev.hide();
    next.hide();

    return $('<table>')
      .addClass($el.attr('class'))
      .css({position: 'absolute'})
      .append(clones.prev, item.clone(), clones.next)
  });

  $el.on('sortstart', (e, ui) => {
    return ui.placeholder.css({ height: placeholderHeight });
  });

  // Places all of the selected items back into the table when sorting is
  // complete.
  $el.on('sortupdate', (e, ui) => {
    const item = $(ui.item);
    item.before(clones.prev);
    item.after(clones.next);
    item.siblings(':hidden').remove();

    const selection = $.map([clones.prev, item, clones.next], (els) => {
      return els.map((i, el) => el).toArray();
    });

    return $el.trigger('multisort.update', [$(selection)]);
  });

  return $el;
}

function loadingSpinner(selection) {
  const spinner = $("<span>").addClass("glyphicon glyphicon-refresh spinning");

  $('td.prioritize').empty();
  selection.find('td.prioritize').html(spinner);
}

function selectAudits($el) {
  const filter = 'tbody > tr';

  $el.selectable({
    filter: filter,
    cancel: 'a, button, .drag-handle, input'
  });

  const selected = () => {
    return $el.find(filter).filter('.ui-selected')
  }

  const hide = (e) => {
    e.preventDefault();
    return selected().removeClass('ui-selected')
  }

  const auditIds = () => {
    return selected()
      .map((i, el) => getAuditPriorityId(el))
      .toArray();
  }

  $el.on('multisort.update', hide)

  return { hide, auditIds, selected }
}

function multisortable($el) {
  const priorityMap = () => {
    return $el
      .find($el.sortable('option', 'items'))
      .toArray()
      .reduce(function(memo, item, i) {
        const newPosition = i + 1;
        const currentPriority = getPriority(item);

        if(newPosition != currentPriority) {
          return memo.concat([{ id: getAuditPriorityId(item), priority: newPosition }])
        } else {
          return memo;
        }
      }, []);
  }

  const bulkUpdatePriorities = (map) => {
    return $.ajax({
      url: $el.data('update-url'),
      method: 'patch',
      contentType: 'application/json',
      data: JSON.stringify({ audit_priorities: map })
    });
  }

  $el.sortable({
    handle: '.drag-handle',
    items: 'tbody > tr',
    axis: 'y',
    opacity: '0.5'
  });

  multiSortableTable($el).on('multisort.update', (e, selection) => {
    loadingSpinner(selection);
    bulkUpdatePriorities(priorityMap());
  })
}

function prioritizedAudits(el) {
  const $el = $(el);

  selectAudits($el);
  multisortable($el);
}

$(document).on('turbo:load', () => {
  $('#audit-priorities[data-update-url]').each((_, el) => {
    prioritizedAudits(el);
  });
});
