import { camelCase, escape, mapKeys, merge } from 'lodash'

const emptyFunction = Function()

class PostBodyConfirmModal {
  constructor (previousResponse, newResponse, replaceCallback = emptyFunction, keepCallback = emptyFunction) {
    this.previousResponse = previousResponse
    this.newResponse = newResponse
    this.replaceCallback = replaceCallback
    this.keepCallback = keepCallback
    this.options = { backdrop: 'static' }
  }

  create () {
    this.element = $(this._template()).appendTo('body')
    this.modal = new bootstrap.Modal(this.element, this.options)
    this.modal.show()

    $(this.element).on('click', '[data-action="keep"]', this._onKeepEvent.bind(this))
    $(this.element).on('click', '[data-action="replace"]', this._onReplaceEvent.bind(this))
    $(this.element).on('hidden.bs.modal', this.destroy.bind(this))
    $(document).one('turbo:before-cache', this.destroy.bind(this))

    return this
  }

  destroy () {
    this.modal.dispose()

    return this
  }

  _onReplaceEvent (e) {
    e.stopPropagation()
    e.preventDefault()

    this.replaceCallback(this.newResponse)
    this.modal.hide()
  }

  _onKeepEvent (e) {
    e.stopPropagation()
    e.preventDefault()

    this.keepCallback(this.previousResponse)
    this.modal.hide()
  }

  _template () {
    const previousTemplate = this.previousResponse.postBody?.template || ''
    const newTemplate = this.newResponse.postBody?.template || ''

    return `
      <div id='post-body-change-confirmation' class='modal' tabindex='-1'>
        <div class='modal-dialog modal-dialog-centered'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h5 class='modal-title'>Keep Current Post Body?</h5>
            </div>
            <div class='modal-body'>
              <p>Do you want to keep the current post body or replace it with the endpoint's default template?</p>

              <label class='fw-bold'>Current Post Body</label>
              <pre>${escape(previousTemplate)}</pre>

              <label class='fw-bold'>Endpoint's Template</label>
              <pre>${escape(newTemplate)}</pre>
            </div>
            <div class='modal-footer'>
              <button type='button' class='btn btn-secondary' data-action='replace'>Use Endpoint's Template</button>
              <button type='button' class='btn btn-primary' data-action='keep'>Keep Current Post Body</button>
            </div>
          </div>
        </div>
      </div>
    `
  }
}

class NestedFields {
  static defaultOptions () {
    return {
      list: 'table tbody',
      triggers: {
        add: '[data-fields]',
        destroy: '[data-fields-remove]'
      }
    }
  }

  constructor (element, options, destroyCallback = emptyFunction) {
    this.element = element
    this.$element = $(this.element)
    this.options = merge(this.constructor.defaultOptions(), options)
    this.destroyCallback = destroyCallback
  }

  create () {
    this.$element.on('click', this.options.triggers.add, this._onAddEvent.bind(this))
    this.$element.on('click', this.options.triggers.destroy, this._onDestroyEvent.bind(this))

    return this
  }

  _onAddEvent (e) {
    const { association, fields } = e.currentTarget.dataset
    const newId = new Date().getTime()
    const regexp = new RegExp('new_' + association, 'g')

    e.stopPropagation()
    e.preventDefault()

    this.$element.find(this.options.list).append(fields.replace(regexp, newId))
  }

  _onDestroyEvent (e) {
    const $row = this.$element.find(e.currentTarget).closest('tr')

    e.stopPropagation()
    e.preventDefault()

    $row.find('input[type="hidden"]').val(true)
    $row.find('input[type="text"]').val('')
    $row.hide()
    this.destroyCallback()
  }
}

class RequestPreview {
  static defaultOptions () {
    return {
      id: null,
      url: null,
      preview: true,
      connectedFields: '[data-request-preview-field]',
      nestedFields: {
        params: {},
        headers: {}
      },
      triggers: {
        refresh: ':input.refresh-request-preview',
        reset: '[data-post-body-reset]'
      },
      samples: {
        curl: '[data-sample-curl]'
      },
      form: {
        postBody: '[data-field="post-body"]',
        endpoint: '[data-field="endpoint"]'
      }
    }
  }

  constructor (element, options) {
    this.element = element
    this.$element = $(this.element)
    this.$headerNestedFields = this.$element.find('[data-nested-fields-for="headers"]')
    this.$paramNestedFields = this.$element.find('[data-nested-fields-for="params"]')
    this.options = merge(this.constructor.defaultOptions(), options)
    this.sampleUrlResponse = {}
    this.modal = null
    this.nestedFields = {
      headers: new NestedFields(
        this.$headerNestedFields[0],
        this.options.nestedFields.headers,
        this._refreshSamples.bind(this)
      ),
      params: new NestedFields(
        this.$paramNestedFields[0],
        this.options.nestedFields.params,
        this._refreshSamples.bind(this)
      )
    }
  }

  create () {
    this._refreshSamples(this.options.id)
    $(document).on('change', this.options.connectedFields, this._onInputChangeEvent.bind(this))
    this.$element.on('change', this.options.triggers.refresh, this._onInputChangeEvent.bind(this))
    this.$element.on('click', this.options.triggers.reset, this._onResetEvent.bind(this))
    this.nestedFields.headers.create()
    this.nestedFields.params.create()

    return this
  }

  _onResetEvent (e) {
    const promptText = "Are you sure you want to reset the post body to the endpoint's default?"

    e.preventDefault()
    e.stopPropagation()

    if (window.confirm(promptText) === true) {
      this._resetPostBody()
      this._refreshSamples()
    }
  }

  _onInputChangeEvent (e) {
    const field = `[data-field="${$(e.currentTarget).data('field')}"]`

    if (field === this.options.form.postBody && e.currentTarget.value.length === 0) {
      const $container = this.$element.find(this.options.samples.postBody)
      const $el = $container.find('pre')

      $el.html('')
    } else if (field === this.options.form.endpoint) {
      this._resetPostBody()
      this._resetSampleCurl()
      this._refreshSamples(false, this.sampleUrlResponse)
    } else {
      this._refreshSamples()
    }
  }

  _refreshPostBody (json, previousResponse) {
    if (json.httpMethod !== 'POST') {
      this._resetPostBody()
    } else if (json.postBody.enable) {
      this._overwritePostBody(json, previousResponse)
    } else {
      this._togglePostBody(false)
    }
  }

  _resetSampleCurl () {
    this.$element.find(this.options.samples.curl).html('')
  }

  _refreshSampleCurl ({ curl }) {
    this.$element.find(this.options.samples.curl).html(curl)
  }

  _resetPostBody () {
    this._togglePostBody(false)
  }

  _confirmPostBodyChanges (json, previousResponse) {
    const replaceCallback = (response, _e) => {
      const { template, params } = response.postBody

      this._togglePostBody(true, template, params)
      this.modal = null
    }

    const keepCallback = (response, _e) => {
      const { template, params } = response.postBody

      this._togglePostBody(true, template, params)
      this._refreshSampleCurl(response)
      this.sampleUrlResponse = response
      this.modal = null
    }

    this.modal = new PostBodyConfirmModal(previousResponse, json, replaceCallback.bind(this), keepCallback.bind(this))
    this.modal.create()
  }

  _overwritePostBody (json, previousResponse) {
    const shouldUpdatePostBody =
      previousResponse && Object.keys(previousResponse).length !== 0 &&
      previousResponse?.postBody?.template !== json.postBody.template
    const { template, params, sample } = json.postBody

    if (shouldUpdatePostBody) {
      this._confirmPostBodyChanges(json, previousResponse)
    } else {
      this._togglePostBody(true, template, params)
    }
  }

  _togglePostBody (show, value, placeholders = []) {
    const $el = this.$element.find(this.options.form.postBody)
    const $container = $el.parents('[data-dynamic-fields-for]')
    const $hint = $el.siblings('.form-text').find('span')
    const html = placeholders.map((x) => `<code>&lt;${x}&gt;</code>`).join(', ')

    $container.toggle(show)
    $el.val(value)
    $hint.html(html)
  }

  _refreshSamples (id, previousResponse) {
    if (!this.options.preview) return

    let formData =
      this.$element.find(':input')
        .add($(this.options.connectedFields))
        .not('[name="_method"]').serialize()

    if (id) formData = [formData, $.param({ id })].join('&')

    $.ajax({
      url: this.options.url,
      method: 'post',
      data: formData,
      dataType: 'json',
      context: this,
      success: (json) => {
        const formattedJson = mapKeys(json, (_v, k) => camelCase(k))

        if (!formattedJson.httpMethod || !formattedJson.url) return

        this.sampleUrlResponse = formattedJson
        this._refreshSampleCurl(formattedJson)
        this._refreshPostBody(formattedJson, previousResponse)
      }
    })
  }
}

export default RequestPreview
