function checkInputSetProgress() {
  const $el = $(".input-set-progress");

  if ($el.length) {
    $.getJSON($el.data("url")).done((status) => {
      if (status.running) {
        setTimeout(checkInputSetProgress, 1000);
      } else {
        Turbo.visit(location.toString());
      }
    });
  }
}

$(document).on("turbo:load", checkInputSetProgress);
