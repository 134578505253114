class Form {
  static defaultOptions = {
    triggers: {
      questions: {
        new: '.new-question',
        destroy: '.remove-question'
      },
      answers: {
        new: '.new-answer',
        destroy: '.remove-answer'
      }
    },
    form: {
      sortableContainer: '.sortable',
      fieldsetContainer: '.fields-container',
      fieldContainer: '.field-container',
      destroyField: '.destroy-field',
      positionField: '.position-field'
    }
  }

  constructor (element) {
    this.$element = $(element)
    this.options = this.constructor.defaultOptions
  }

  create () {
    this._createSortable(this.$element.find(this.options.form.sortableContainer))
    this.$element.on(
      'click',
      [this.options.triggers.questions.new, this.options.triggers.answers.new].join(', '),
      this._onNewClick.bind(this)
    )
    this.$element.on(
      'click',
      [this.options.triggers.questions.destroy, this.options.triggers.answers.destroy].join(', '),
      this._onDestroyClick.bind(this)
    )

    return this
  }

  _onNewClick (event) {
    const $target = $(event.target)
    const { association, fields } = $target.data()
    const $list = $target.closest(this.options.form.fieldsetContainer).find(`${this.options.form.sortableContainer}:first`)
    const id = new Date().getTime()
    const regexp = new RegExp('new_' + association, 'g')

    event.preventDefault()
    event.stopPropagation()

    $list.append(fields.replace(regexp, id))
    this._updateSortablePosition($list)
    this._createSortable($list.find(this.$element.find(this.options.form.sortableContainer)))
    $list.trigger('select2:init')
  }

  _onDestroyClick (event) {
    const $target = $(event.target)

    event.preventDefault()
    event.stopPropagation()

    $target.closest(this.options.form.fieldContainer).find(this.options.form.destroyField).val(true)
    $target.closest(this.options.form.fieldContainer).hide()
  }

  _createSortable ($element) {
    $element.sortable({
      handle: '.drag-handle',
      stop: (_e, ui) => this._updateSortablePosition(ui.item.closest(this.options.form.sortableContainer))
    })
  }

  _updateSortablePosition ($sortable) {
    $sortable
      .find(`${this.options.form.positionField}`)
      .not($sortable.find(`${this.options.form.sortableContainer} ${this.options.form.positionField}`))
      .each((i, input) => input.value = i)
  }
}

$(document).on('turbo:load', () => {
  const $formList = $('.task-template-form')

  $.map($formList, (el) => (new Form(el)).create())
})

export default Form
