$(document).on('click', 'table#scrapes .description-edit__icon', function() {
  const link = $(this);

  if (link.data('popover-active')) {
    link.data('popover-active', false);
    link.popover('dispose');
  } else {
    link.data('popover-active', true);
    $.get(link.data('url')).done(
      (html) => link.popover({ content: html, html: true }).popover('show')
    )
  }
});

const hideDescriptionForm = function() {
  const popoverID = $(this).closest('.popover').attr('id');
  const link = $(`[aria-describedby=${popoverID}]`)

  link.data('popover-active', false)
  link.popover('dispose')
}

$(document).on('turbo:submit-end', '.description-edit__form', hideDescriptionForm);
$(document).on('click', '.description-edit__form .cancel', hideDescriptionForm);
