const getExperimentId = (el) => $(el).data('experiment-id');
const getPriority = (el) => $(el).data('priority');

// Adds the ability to sort multiple items at once based on a selection.
function multiSortableTable($el) {
  let placeholderHeight = null;
  let clones = null;

  $el.sortable('option', 'helper', (_e, item) => {
    const prev = $(item.prevAll('.ui-selected').toArray().reverse());
    const next = item.nextAll('.ui-selected');

    clones = { prev: prev.clone(), next: next.clone() };
    placeholderHeight = item.outerHeight() * (prev.length + next.length + item.length + 1);

    prev.hide();
    next.hide();

    return $('<table>')
      .addClass($el.attr('class'))
      .css({position: 'absolute'})
      .append(clones.prev, item.clone(), clones.next)
  });

  $el.on('sortstart', (_e, ui) => {
    return ui.placeholder.css({ height: placeholderHeight });
  });

  // Places all of the selected items back into the table when sorting is
  // complete.
  $el.on('sortupdate', (_e, ui) => {
    const item = $(ui.item);
    item.before(clones.prev);
    item.after(clones.next);
    item.siblings(':hidden').remove();

    const selection = $.map([clones.prev, item, clones.next], (els) => {
      return els.map((_i, el) => el).toArray();;
    });

    return $el.trigger('multisort.update', [$(selection)]);
  });

  return $el;
}

function selectionPopover($el, options) {
  const filter = $el.selectable('option', 'filter');
  let popover = null;

  const selected = () => $el.find(filter).filter('.ui-selected');
  const content = () => options.content(selected());

  const hide = () => {
    const dfd = $.Deferred();

    if (!popover) return dfd.resolve().promise();

    popover.popover('hide');
    popover.one('hidden.bs.popover', () => {
      popover.popover('dispose');
      popover = null;

      return dfd.resolve();
    });

    return dfd.promise();
  }

  const show = (_e, _ui) => {
    const target = selected().last();

    if(target.is(popover)) {
      popover.popover('show');
      return
    }

    hide().done(() => {
      popover = target
      popover.popover({
        content: content,
        placement: 'bottom',
        trigger: 'manual',
        html: true
      });

      popover.popover('show');
    });
  }

  $el.on('selectablestop', show);
  $el.on('sortstart', hide);

  return { show, hide };
}

function loadingSpinner(selection) {
  const spinner = $("<span>").addClass("glyphicon glyphicon-refresh spinning");

  $('td.prioritize').empty();
  selection.find('td.prioritize').html(spinner);
}

function loadingSpinnerForUjs($el) {
  $el.on('ajax:before', (e) => loadingSpinner($(e.target).closest('tr')));
}

function selectExperimentsWithPopover($el, options) {
  const filter = 'tbody > tr';

  $el.selectable({
    filter: filter,
    cancel: 'a, button, .drag-handle, input'
  });

  const popover = selectionPopover($el, { content: options['popoverContent'] })
  const selected = () => $el.find(filter).filter('.ui-selected');

  const hide = (e) => {
    e?.preventDefault();
    popover.hide();

    return selected().removeClass('ui-selected');
  }

  const experimentIds = () => {
    return selected()
      .map((_i, el) => getExperimentId(el))
      .toArray();
  }

  $(document).on('click', '.popover-body .cancel-button', hide);
  $el.on('multisort.update', hide)

  return { hide, experimentIds, selected };
}


function prioritizedExperiments($el) {
  loadingSpinnerForUjs($el);

  const selection = selectExperimentsWithPopover($el, {
    popoverContent: (selected) => `
      <button class="btn btn-primary unprioritize-button">Unprioritize ${selected.length}</button>
      <button class="btn btn-link cancel-button">Cancel</button>
    `
  });

  const unprioritizeExperiments = (e) => {
    e.preventDefault();
    loadingSpinner(selection.selected());

    const data = { 'experiment_id[]': selection.experimentIds(), _method: 'DELETE' };
    $.post($el.data('unprioritize-url'), data);

    return selection.hide();
  }

  $(document).on('click', '.popover-body .unprioritize-button', unprioritizeExperiments);

  const experimentPriorityMap = () => {
    return $el
      .find($el.sortable('option', 'items'))
      .toArray()
      .reduce((memo, el, i) => {
        const newPosition = i + 1;
        const currentPriority = getPriority(el);

        if (newPosition != currentPriority) {
          return memo.concat([{ id: getExperimentId(el), priority: newPosition }]);
        }
        else {
          return memo
        }
      }, []);
  }

  const bulkUpdatePriorities = (map) => {
    return $.ajax({
      url: $el.data('bulk-update-url'),
      method: 'patch',
      contentType: 'application/json',
      data: JSON.stringify({ experiment_priorities: map })
    });
  }

  $el.sortable({
    handle: '.drag-handle',
    items: 'tbody > tr',
    axis: 'y',
    opacity: '0.5'
  });

  multiSortableTable($el).on('multisort.update', (_e, selection) => {
    loadingSpinner(selection);
    bulkUpdatePriorities(experimentPriorityMap());
  });
}

window.unprioritizedExperiments = ($el) => {
  loadingSpinnerForUjs($el);

  const selection = selectExperimentsWithPopover($el, {
    popoverContent: (selected) => `
      <button class="btn btn-primary prioritize-button">Prioritize ${selected.length}</button>
      <button class="btn btn-link cancel-button">Cancel</button>
    `
  });

  const prioritizeExperiments = (e) => {
    e.preventDefault();
    loadingSpinner(selection.selected());

    const data = { 'experiment_id[]': selection.experimentIds() };
    $.post($el.data('prioritize-url'), data);

    return selection.hide();
  }

  return $(document).on('click', '.popover-body .prioritize-button', prioritizeExperiments);
}

window.refreshUnprioritizedExperiments = () => {
  const url = $('#unprioritized-experiments').data('current-page-url');

  return $.get(url);
}

$(document).on('turbo:load', () => {
  prioritizedExperiments($('#prioritized-experiments[data-bulk-update-url]'));
  unprioritizedExperiments($('#unprioritized-experiments[data-prioritize-url]'));
});
