import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="ab--plan-experiment-status"
export default class extends Controller {
  static targets = [ 'showHistoryLink', 'hideHistoryLink', 'historyTable', 'hideOnRequestingChanges', 'hideOnApproving' ]

  requestChanges() {
    for (const elt of this.hideOnApprovingTargets) {
      elt.classList.remove('d-none')
    }

    for (const elt of this.hideOnRequestingChangesTargets) {
      elt.classList.add('d-none')
    }
  }

  approve() {
    for (const elt of this.hideOnApprovingTargets) {
      elt.classList.add('d-none')
    }

    for (const elt of this.hideOnRequestingChangesTargets) {
      elt.classList.remove('d-none')
    }
  }

  showHistory() {
    this.showHistoryLinkTarget.classList.add('d-none')
    this.hideHistoryLinkTarget.classList.remove('d-none')
    this.historyTableTarget.classList.remove('d-none')
  }

  hideHistory() {
    this.showHistoryLinkTarget.classList.remove('d-none')
    this.hideHistoryLinkTarget.classList.add('d-none')
    this.historyTableTarget.classList.add('d-none')
  }
}
