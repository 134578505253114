$(document).on("change", ".toggle-notify", (e) => {
  const $checkbox = $(e.currentTarget);

  const data = {
    id: $checkbox.data("id"),
    recipient_type: "User",
    recipient_id: $checkbox.data("recipientId"),
    notifier_type: "Scrape",
    notifier_id: $checkbox.data("notifierId"),
    notification_type: $checkbox.data("notificationType")
  };

  const options = {
    method: $checkbox.data("method"),
    url: $checkbox.data("url"),
    timeout: 3000,
    data: {
      slack_notification: data
    }
  };

  $.ajax(options);
});
