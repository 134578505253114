import Store               from '../utils/store/store';
import ExperimentSelector  from './components/experiment_selector';
import DataSourceSelector  from './components/data_source_selector';

$(document).on('turbo:load', () => {
  $('#prune-ui-wrapper').each(() => {
    const store = new Store({});
    [
      DataSourceSelector,
      ExperimentSelector,
    ].map((klass) => {
      return new klass({ store: store });
    }).forEach((instance) => {
      instance.initialize();
    });
  });
});
