import fuzzyMatch from 'utils/fuzzy_match'
import { getElementDropdownParent } from '../utils/select2'

const submitForm = () => {
  $('form#roster_report-form').trigger("submit")
}
$(document).on('change', '.roster-report-form-select', submitForm)

// enables searching in select2 field with <optgroups>
// https://select2.org/searching#matching-grouped-options
function matchStart(params, data) {
  // If there are no search terms, return all of the data
  const term = (params.term || '').trim()
  if( term === '') {
    return data;
  }

  // Skip if there is no 'children' property
  if (typeof data.children === 'undefined') {
    return null;
  }

  // `data.children` contains the actual options that we are matching against
  let filteredChildren = [];
  $.each(data.children, function (idx, child) {
    let matches, score, _;
    [matches, score, _] = fuzzyMatch(child.text, term);
    if (matches){
      child.score = score;
      filteredChildren.push(child);
    }
  });

  // If we matched any of the  group's children, then set the matched children on the group
  // and return the group object
  if (filteredChildren.length) {
    let modifiedData = $.extend({}, data, true);
    modifiedData.children = filteredChildren;

    // You can return modified objects from here
    // This includes matching the `children` how you want in nested data sets
    return modifiedData;
  }

  // Return `null` if the term should not be displayed
  return null;
}

const initSelect2WithOptGroups = (element) => {
  $(element).find(".roster-report-form-select").each((_index, el) => {
    $(el).select2({
      matcher: matchStart,
      dropdownParent: getElementDropdownParent(el),
      width: '100%'
    });
  });
}
$(document).on('turbo:load', () => initSelect2WithOptGroups(document))
$(document).on("show.bs.modal cocoon:after-insert select2:init", (e) => initSelect2WithOptGroups(e.target))
$(document).on('inserted.bs.popover', (e) => {
  const popover = $(e.target).data('bs.popover')?.tip || bootstrap.Popover.getOrCreateInstance(e.target).tip;

  initSelect2WithOptGroups(popover);
})
