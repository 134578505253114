import { createTransport } from "@sentry/core"
import { clearCachedFetchImplementation, getNativeFetchImplementation } from '@sentry/browser/cjs/transports/utils'
import { rejectedSyncPromise } from '@sentry/utils'

// see https://github.com/getsentry/sentry-javascript/blob/7.73.0/packages/browser/src/transports/fetch.ts
// adds the current X-CSRF-Token header to the makeFetchTransport implementation here 👆
const makeFetchTransportWithCSRFToken = (options, nativeFetch = getNativeFetchImplementation()) => {
  let pendingBodySize = 0
  let pendingCount = 0

  const makeRequest = (request) => {
    const headers = { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content, ...options.headers }
    const requestSize = request.body.length
    pendingBodySize += requestSize
    pendingCount++

    const requestOptions = {
      body: request.body,
      method: 'POST',
      referrerPolicy: 'origin',
      headers: headers,
      keepalive: pendingBodySize <= 60_000 && pendingCount < 15,
      ...options.fetchOptions
    };

    try {
      return nativeFetch(options.url, requestOptions).then(response => {
        pendingBodySize -= requestSize
        pendingCount--
        return {
          statusCode: response.status,
          headers: {
            'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
            'retry-after': response.headers.get('Retry-After'),
          },
        }
      })
    } catch (e) {
      clearCachedFetchImplementation()
      pendingBodySize -= requestSize
      pendingCount--
      return rejectedSyncPromise(e)
    }
  }

  return createTransport(options, makeRequest)
}

export default makeFetchTransportWithCSRFToken
