import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="ab--plan-new-experiment"
export default class extends Controller {
  static targets = [ "areaSelector" ]

  async areaChange() {
    // Get url from selected <option>
    const url = this.areaSelectorTarget.options[this.areaSelectorTarget.selectedIndex].dataset.timelineUrl

    await get(url, { responseKind: "turbo-stream" })
  }
}
