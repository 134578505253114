const showOriginalAnswerAndComment = (e) => {
  const answerInputField = $(e.target)

  const originalAnswerSpan = answerInputField.next('span.original-answer-block')
  const originalCommentsSpan = $('span.original-comments-block')

  let answerVal
  let originalAnswerVal

  if (e.target.tagName == 'TEXTAREA') {
    answerVal = answerInputField.val()
    originalAnswerVal = originalAnswerSpan.children('span.original-answer').text().trim()
  } else
  {
    answerVal = parseInt( answerInputField.val(), 10 )
    originalAnswerVal = parseInt( originalAnswerSpan.data('answer-id'), 10 )
  }

  if (answerVal == originalAnswerVal) {
    originalAnswerSpan.addClass('d-none')
    originalCommentsSpan.addClass('d-none')
  } else {
    originalAnswerSpan.removeClass('d-none')
    originalCommentsSpan.removeClass('d-none')
  }
}

$(document).on('change', '.task-judgment-answer-select', showOriginalAnswerAndComment)
$(document).on('blur', '.task-judgment-answer-text', showOriginalAnswerAndComment)
