export default {
  "black": "#000",
  "white": "#fff",
  "grayDark": "#333333",
  "chartAqua": "#17becf",
  "chartBrown": "#8c564b",
  "chartBlue": "#1f77b4",
  "chartGray": "#7f7f7f",
  "chartGreen": "#2ca02c",
  "chartLime": "#bcbd22",
  "chartOrange": "#ff7f03",
  "chartPink": "#e377c2",
  "chartPurple": "#9467bd",
  "chartRed": "#d62728",
  "stateInfoBg": "#d9edf7",
  "stateInfoText": "#31708f",
  "stateSuccessBg": "#dff0d8",
  "stateWarningBg": "#fcf8e3",
  "stateWarningText": "#8a6d3b",
  "stateDangerBg": "#f2dede",
  "stateDangerText": "#a94442",
  "presentationBackgroundColor": "#1c1c1e",
  "presentationBorderColor": "#333335"
};
