import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ab--significant-change-value"
export default class extends Controller {
  static values = {
    initial: String,
    alwaysChanged: { type: Boolean, default: false }
  }

  connect() {
    this.initialValue = this.element.value
  }

  hasChanged() {
    if (this.alwaysChangedValue) { return true }

    return this.element.value != this.initialValue
  }
}
