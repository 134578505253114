import WithWidgetOptions from "apex/utils/chart_options/extenders/with_widget_options";
import VerticalBarChartLpaDashboardOptions
  from "apex/utils/chart_options/vertical_bar_chart_lpa_dashboard_options";
import { format } from "d3";

function tooltipFormatter(label, dataPoint, total) {
  return `
  <div class="apexcharts-tooltip-title">${label}</div>
  <dl class="bg-white d-flex justify-content-between mb-0 p-1">
    <dt class="pe-1"><small><strong>Percentage</strong></small></dt>
    <dd class="ps-1"><small>${format(".1%")(dataPoint / total)}</small></dd>
  </dl>
  `;
}

$(document).on("turbo:load", () => {
  $(".lpa-dashboard__patterns-bar-chart").each((_, el) => {
    const data = $(el).data("chart-data");
    const tooltipOptions = { enabled: true,
                             custom: ({ dataPointIndex }) => tooltipFormatter(data.meta.dimension_categories[dataPointIndex], data.series[0].data[dataPointIndex], data.total) }

    if (Object.keys(data.series)?.length) {
      const options = new WithWidgetOptions(data, VerticalBarChartLpaDashboardOptions).build();

      options.tooltip = tooltipOptions
      const chart = new ApexCharts(el, options);

      chart.render();
      $(document).one("turbo:before-cache", () => chart.destroy());
    } else {
      const message = data.meta?.error_message || 'No data available';

      $(el).empty().append(`
        <div class="d-flex align-items-center justify-content-center p-5">
          <p class="lead">${message}</p>
        </div>
      `);
    }
  });
});
