import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).sortable({
      items: '.drag-container',
      handle: '.drag-handle',
      stop: function(_e, ui) {
        const item = $(ui.item)
        const position = item.parent().children().index(item) + 1;

        $.ajax({
          url: item.data('url'),
          method: 'patch',
          contentType: 'application/json',
          data: JSON.stringify({favorite: { position: position }})
        })
      }
    })
  }

  disconnect() {
    $(this.element).sortable('destroy')
  }
}
