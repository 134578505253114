class ArtistAffinityBadge {
  constructor(artistName, bucket) {
    this.artistName = artistName.replace(/"/g, "");
    this.bucket = bucket;
  }

  static render(affinities) {
    (affinities || []).forEach((affinity) => new this(affinity.name, affinity.bucket_id).render())
  }

  render() {
    const config = this.badgeConfig();
    const el = `<span class="affinity badge badge-${config.type}">${config.text}</span>`;

    $(`.affinity-artist-name[data-artist-name="${this.artistName}"] .affinities`).append(el);
  }

  badgeConfig() {
    switch (this.bucket) {
      case  1: return { type: "success",   text: "Very High" }
      case  2: return { type: "info",      text: "High" }
      case  3: return { type: "primary",   text: "Medium" }
      case  4: return { type: "secondary", text: "Low" }
      case  5: return { type: "secondary", text: "Very Low" }
      case -1: return { type: "warning",   text: "Negative" }
      case -2: return { type: "danger",    text: "Very Negative" }
      default: return { type: "",          text: "" }
    }
  }
}

$(document).on("turbo:load", () => {
  ArtistAffinityBadge.render($(".researchable-affinities").data("affinities"));
});

export default ArtistAffinityBadge;
