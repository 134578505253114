import styles from 'utils/colors';
import { merge } from 'lodash';

import BaseOptions from './base_options';
import formatterFor from '../formatter_for';

import RadarChartTooltip from '../custom_tooltips/radar_chart_tooltip';
import RadarChartDataNormalizer from '../data_normalizers/radar_chart_data_normalizer';

class RadarChartOptions extends BaseOptions {
  static defaultOptions() {
    return merge(super.defaultOptions(), {
      chart: {
        type: 'radar',
        events: {
          updated: this.resetMeasureLabels
        }
      },
      colors: [
        styles.chartBlue,
        styles.chartOrange,
        styles.chartGreen,
        styles.chartPurple,
        styles.chartBrown,
        styles.chartPink,
        styles.chartGray,
        styles.chartLime,
        styles.chartAqua,
        styles.chartRed
      ],
      grid: {
        padding: {
          top: 40
        }
      },
      stroke: {
        width: 1
      },
      yaxis: {
        show: false
      }
    });
  }

  get presentationOptions() {
    return {
      theme: {
        mode: "dark"
      },
      chart: {
        background: styles.presentationBackgroundColor
      },
      title: {
        style: {
          color: styles.white
        }
      },
    };
  }

  constructor(chartData) {
    super(chartData);

    this.tooltip = new RadarChartTooltip(this.chartData)
    this.normalizer = new RadarChartDataNormalizer(this.chartData);
    this.dataDependentOptions = this.setDataDependentOptions();
  }

  unsetMeasureLabelsToAllowFullSizeChartRender = () =>
    this.chartData.meta.measure_labels.map(() => "")

  resetMeasureLabels = (context) => {
    const nodes = $(context.el).find(".apexcharts-xaxis text")

    this.measureLabelsDecoratedWithNormalizedRanges().forEach((decoratedLabel, forMeasure) =>
      $(nodes[forMeasure]).html(decoratedLabel)
    )
  }

  measureLabelsDecoratedWithNormalizedRanges = () => {
    const ranges = this.normalizer.rangesByMeasure;
    const formatters = this.chartData.meta.measure_formats.map(formatterFor)

    return this.chartData.meta.measure_labels.map((baseLabel, forMeasure) =>
      `${baseLabel} (${ranges[forMeasure].map(formatters[forMeasure]).join(", ")})`
    )
  }

  setDataDependentOptions = () =>
  ({
    series: this.normalizer.normalizedSeries,
    title: {
      text: this.chartData.meta.title_text,
    },
    tooltip: {
      custom: this.tooltip.render
    },
    xaxis: {
      categories: this.unsetMeasureLabelsToAllowFullSizeChartRender()
    }
  })
}

export default RadarChartOptions;
