$(document).on('click', '.dropdown-drawer__parent', function (e) {
  e.stopPropagation();
  e.stopImmediatePropagation();

  const $target = $(e.currentTarget);
  $target.toggleClass('dropdown-drawer__parent--open');

  const dropdownChildList = $target.next();
  dropdownChildList.toggleClass('dropdown-drawer__child-list--open');

  const glyphiconMenuRight = $target.find('.glyphicon-menu-right');
  glyphiconMenuRight.toggleClass('glyphicon-menu-right--open');
});

$(document).on('click', '.dropdown-menu a.dropdown-toggle', function(e) {
  e.preventDefault();
  e.stopImmediatePropagation();

  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
  }

  const $subMenu = $(this).next('.dropdown-menu');
  $subMenu.toggleClass('show');

  $(this).parents('.add__dropdown').on('hidden.bs.dropdown', function(e) {
    $('.dropdown-submenu .show').removeClass('show');
  });
});
