import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['query']
  static values = { columName: String, predicate: String }

  submit(e) {
    this.queryTarget.name = this.queryTarget.name.replace(
      `${this.columNameValue}_${this.predicateValue}`,
      `${this.columNameValue}_${e.target.value}`
    )

    this.element.closest('form').requestSubmit()
  }
}
