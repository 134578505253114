function addDateFilterFields(association, fields) {
  const newId = (new Date()).getTime();
  const regexp = new RegExp(`new_${association}`, "g");

  $("#tabular-report-date-filters-fields .add-button").before(fields.replace(regexp, newId));
}

$(document).on("click", "#tabular-report-date-filters-fields .new-date-filter", (e) => {
  const data = $(e.currentTarget).data();

  e.preventDefault();
  addDateFilterFields(data.association, data.fields);
});

function removeDateFilterFields($link) {
  $link.closest(".date-filter").find(".destroy-field").val(true);
  $link.closest("li").hide();
}

$(document).on("click", "#tabular-report-date-filters-fields .remove-icon", (e) => {
  e.preventDefault();
  removeDateFilterFields($(e.currentTarget));
});

$(document).on("change", "#tabular-report-date-filters-fields .date-filter-type-select", (e) => {
  const type = $(e.currentTarget).val();
  $(e.currentTarget).closest(".date-filter").attr("data-type", type);
});

function updatePeriodSample(el) {
  const key = $(el).val();

  $(el).closest(".period-selector")
    .find(".sample-period").hide().end()
    .find(`[data-key="${key}"]`).show();
}

$(document).on("change", ".period-selector select, .date-filter-type select", (e) => updatePeriodSample(e.currentTarget));
$(document).on("turbo:load", () => updatePeriodSample($(".period-selector select")));
