class Buffer {
  constructor() {
    this.collection = [];
  }

  push(object) {
    this.collection.push(object);
  }

  yield(fn) {
    if (this.collection.length) {
      fn(this.collection)
      this.collection = [];
    }
  }
}

export default Buffer;
