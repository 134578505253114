import styles from 'utils/colors';
import { merge } from "lodash";
import { format } from "d3";
import { minMax } from "utils/array"

function tooltipKeyValue(label, value, formatString) {
  if (typeof value == "undefined") {
    return "";
  } else {
    return `
      <dt class="col-6 pe-1">${label}</dt>
      <dd class="col-6 ps-1">${format(formatString)(value)}</dd>
    `;
  }
}

function tooltipFormatter(dataPoint) {
  return `
    <div class="apexcharts-tooltip-title">${dataPoint.x}</div>
    <div class="apexcharts-tooltip-series-group apexcharts-active">
      <dl class="apexcharts-tooltip-text row no-gutters mb-0">
        ${tooltipKeyValue("Score", dataPoint.meta.score, "")}
        ${tooltipKeyValue("Input Count", dataPoint.meta.count, ",")}
        ${tooltipKeyValue("Input Proportion", dataPoint.meta.proportion, ".3p")}
      </dl>
    </div>
  `;
}

function createChart(container, data) {
  const yValues = data.values.map((e) => e.y);
  const [yMin, yMax] = minMax(yValues)

  let options = {
    title: {
      text: data.key,
      align: "center"
    },
    series: [{ data: yValues }],
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        colors: ["#333"]
      }
    },
    xaxis: {
      categories: data.values.map(e => e.x),
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        rotate: -25,
        hideOverlappingLabels: false
      }
    },
    yaxis: {
      show: false,
      min: 0,
      max: yMax * 1.1
    },
    chart: {
      animations: {
        enabled: false
      },
      width: "100%",
      height: "100%",
      type: "bar",
      toolbar: {
        show: false
      },
      events: {
        mounted: function(ctx, options) {
          const maxBarWidth = 50;
          const chartWidth = ctx.el.offsetWidth;
          const bars = ctx.bar.visibleItems;

          let widthRatio = ((bars * maxBarWidth) / chartWidth) * 100;

          if (widthRatio > 90) { widthRatio = 90; }

          chart.updateOptions({
            plotOptions: {
              bar: {
                columnWidth: `${widthRatio}%`
              }
            }
          }, false, true);
        }
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top"
        }
      }
    },
    tooltip: {
      enabled: true,
      custom: ({ dataPointIndex }) => tooltipFormatter(data.values[dataPointIndex])
    },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      }
    }
  };

  if (data.metric == 'side_preference') {
    options = merge({}, options, {
      yaxis: {
        min: yMin - 0.1,
        max: yMax + 0.1
      },
      xaxis: {
        axisBorder: { show: false }
      },
      annotations: {
        yaxis: [{
          borderColor: '#e0e0e0',
          strokeDashArray: 0,
          x: 0
        }]
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: -2,
                to: 0,
                color: styles.chartOrange
              },
              {
                from: 0,
                to: 2,
                color: styles.chartBlue
              }
            ]
          }
        }
      }
    });
  }

  if ($('.lpa-dashboard').length > 0) {
    options = merge({}, options, {
      chart: {
        height: '275',
        events: {
          mounted: {}
        }
      },
      fill: {
        opacity: 1
      },
      title: {
        align: 'left',
        offsetY: 13.5,
        style: {
          fontSize:  '1rem',
          fontWeight:  'normal',
          color:  '#666666'
        },
      },
      xaxis: {
        axisTicks: {
          show: true
        }
      },
      yaxis: {
        forceNiceScale: true,
        show: true,
        title: {
          text: 'Inputs',
        },
        labels: {
          offsetX: 20
        }
      },
      grid: {
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          top: 20,
          right: 20,
          bottom: 20,
          left: 30
        }
      }
    });

    if (document.body.classList.contains("presentation")) {
      options = merge({}, options, {
        theme: {
          mode: "dark"
        },
        chart: {
          background: styles.presentationBackgroundColor
        },
        title: {
          style: {
            color: styles.white
          }
        },
        dataLabels: {
          style: {
            colors: [styles.white]
          }
        },
        grid: {
          borderColor: styles.presentationBorderColor
        }
      })
    }
  }

  const chart = new ApexCharts(container.get(0), merge(options, container.data()));
  chart.render();

  $(document).one("turbo:before-cache", () => chart.destroy());
}

function showChart(metric) {
  $(".metadata-chart")
    .hide()
    .filter(`[data-metric="${metric}"]`)
    .show()
    .trigger("update")
}

function registerChartListeners() {
  $(".metadata-chart").one("update", (e) => {
    const $el = $(e.currentTarget);

    const params = {
      metadata_attribute: $el.data("metadata-attribute"),
      metric: $el.data("metric")
    }

    const path = $el.data('url') || document.location.href

    $.getJSON(path, params, (data) => {
      createChart($el.find('.chart'), data[0]);
    });
  })
}

function storageKey() {
  return `${location.pathname}:chartMetric`;
}

function registerSelectorListeners() {
  const selector = $("#chart-metric-selector").each((_, el) => {
    const metric = sessionStorage.getItem(storageKey()) || $(el).val();
    $(el).val(metric);
    showChart(metric);
  });

  if (selector.length == 0) {
    $(".metadata-chart").trigger("update");
  }
}

$(document).on("change", "#chart-metric-selector", (e) => {
  const metric = $(e.currentTarget).val();
  showChart(metric);
  sessionStorage.setItem(storageKey(), metric);
});

$(document).on("turbo:load", () => {
  registerChartListeners();
  registerSelectorListeners();
});
