import Component from '../../utils/store/lib/component.js';

const WRAPPER = '#prune-ui-wrapper';
const DATA_SOURCE_SELECTOR = '#prune-data-source-selector';
const PRUNE_EXPERIMENT_DATA = '#prune-experiment-data';

export default class DataSourceSelector extends Component {
  constructor(props) {
    super(props);

    this.$detailsUrl = $(WRAPPER).data('details-url');
    this.$dataSourceSelect = $(DATA_SOURCE_SELECTOR);
    this.$experimentData = $(PRUNE_EXPERIMENT_DATA);
    this.$dataSourcesUrl = this.$dataSourceSelect.data('details-url');
    this.$defaultValue = this.$dataSourceSelect.data('selected-value');

    this.store.events.subscribe('set-experiment-id', (id) => {
      this.fetchDataSourcesForExperiment(id)
        .then((data) => {
          this.populateDataSources(data);
          this.store.events.publish('refresh-details');
        });
    });

    this.store.events.subscribe('refresh-details', () => {
      if (this.store.state.experiment_id && this.store.state.data_source_id) {
        $.get(this.$detailsUrl, {
          'experiment_id': this.store.state.experiment_id,
          'data_source_id': this.store.state.data_source_id
        }).done((response) => {
          this.$experimentData.html(response);
        }).fail((response) => {
          console.log(response);
        });
      } else {
        this.$experimentData.html('');
      }
    });

    this.$dataSourceSelect.on('change', (e) => {
      this.store.state.data_source_id = $(e.target).val();
      this.store.events.publish('refresh-details');
    });
  }

  async fetchDataSourcesForExperiment(id) {
    return await $.get(this.$dataSourcesUrl, { experiment_id: id });
  }

  populateDataSources(values) {
    this.$dataSourceSelect.empty();
    this.store.state.data_source_id = null;

    if (values.length > 0) {
      this.$dataSourceSelect.prop('disabled', false);
      this.$dataSourceSelect.append(new Option('Select a Data Source', 0, true, true));

      values.forEach((data_source) => {
        if (data_source.value === this.$defaultValue) {
          this.store.state.data_source_id = this.$defaultValue;
        }
        const newOption = new Option(data_source.name, data_source.value, false, data_source.value === this.$defaultValue);
        this.$dataSourceSelect.append(newOption);
      });
    } else {
      this.$dataSourceSelect.append(new Option('No values for this experiment', 0, true, true));
    }
  }
}
