import Buffer from './buffer';
import { reporter } from './reporter';

const extractImpressionData = (data) => {
  if(typeof(data) == 'object') {
    return data.meta.instrument_impression_data;
  } else {
    return $(data).filter('[data-instrument-impression]').data('instrument-impression');
  }
}

const buffer = new Buffer();
let impressionId = 0;

$(document).on('widget:loaded', (e, data) => {
  const target = $(e.target);
  const impressionData = extractImpressionData(data);
  const portlet = target.closest('li.portlet');

  buffer.push(Object.assign({}, impressionData, {
    impressionTimes: [ Date.now() ],
    impressionIndex: portlet.index(),
    impressionId: impressionId++
  }));
});

$(document).on('instrument:last-call turbo:before-visit', () => {
  buffer.yield((impressions) => reporter.recordEvent("impressions", { impressions }));
});
