import ApexCharts from "apexcharts";

import VerticalBarChartOptions from './utils/chart_options/vertical_bar_chart_options';

class VerticalBarChart {
  constructor(attributes) {
    this.attributes = attributes;
    this.element = this.attributes.el;
    this.id = this.element.attr('id');
    this.chart = null;
    this.defaultDataErrorMessage = "No data available";
  }

  load = () => $.getJSON(this.attributes.dataUrl, this.updateChartData).fail(this.handleLoadFailure)

  handleLoadFailure = (xhr) => {
    if (xhr.responseJSON) {
      this.renderDataErrorMessage(xhr.responseJSON.message)
    } else {
      this.renderDataErrorMessage(xhr.statusText)
    }
  }

  destroyChart = () => {
    this.chart && this.chart.destroy();
    this.chart = null;
    this.element.empty();
  }

  buildChart = (json) => {
    const initialOptions = {
      chart: {
        height: 350
      },
      title: {
        text: json.meta.title_text
      },
      series: [{
        name: json.data.labels,
        data: json.data.values
      }],
      xaxis: {
        categories: json.data.labels
      },
      yaxis: {
        min: 0,
        forceNiceScale: true
      },
      meta: {
        measure_labels_format: 'integer',
        maxYValue: Math.max(...json.data.values)
      }
    };
    const chartOptions = new VerticalBarChartOptions(initialOptions).build();
    this.chart = new ApexCharts(this.element.get(0), chartOptions);
  }

  renderDataErrorMessage = (message) =>
    this.element.append(`
      <div class="no-data-container d-flex justify-content-center">
        <div class="no-data">${message.length ? message : this.defaultDataErrorMessage}</div>
      </div>
    `)

  render = () => this.chart && this.chart.render()

  updateChartData = (json) => {
    this.destroyChart();

    if (json.data) {
      this.buildChart(json);
      this.render();
    } else {
      this.renderDataErrorMessage(json.meta.error_message);
    }
  }
}

export default VerticalBarChart;
