import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'selectField']

  connect() {
    this.updateButtonText()
  }

  updateButtonText() {
    const experimentCount = this.selectFieldTarget.selectedOptions.length

    if (experimentCount > 0) {
      this.submitButtonTarget.disabled = false;
      this.submitButtonTarget.value = `Create Project and ${experimentCount * 2} new experiments`;
    } else {
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.value = 'Choose at least one input set'
    }
  }
}
