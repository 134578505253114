$(document).on('turbo:load', () => {
  function createChart(container, keys, values) {
    var options = {
      series: [{ data: values }],
      dataLabels: { enabled: false },
      xaxis: {
        categories: keys,
        axisTicks: { show: false },
        labels: { show: false }
      },
      yaxis: {
        max: 1,
        labels: { style: { fontSize: '14px' } }
      },
      chart: {
        height: 30 * keys.length,
        type: 'bar',
        toolbar: { show: false }
      },
      plotOptions: { bar: { horizontal: true } },
      tooltip: { enabled: false },
      grid: {
        padding: {
          top: -20,
          left: 20,
          right: 20
        },
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: false } }
      }
    };

    var chart = new ApexCharts(container, options);
    chart.render();

    document.addEventListener('turbo:before-cache', () => chart.destroy(), { once: true });
  }

  $('.cohort-affinity').each(function() {
    const json   = $(this).data('json')
    const keys   = Object.keys(json)
    const values = Object.values(json)

    createChart(this, keys, values)
  })
})
