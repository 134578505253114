import colors from 'utils/colors';

const colorsByLevel = {
  danger: {
    bg: colors.stateDangerBg,
    text: colors.stateDangerText
  },
  info: {
    bg: colors.stateInfoBg,
    text: colors.stateInfoText
  },
  success: {
    bg: colors.stateSuccessBg,
    text: colors.stateSuccessText
  },
  warning: {
    bg: colors.stateWarningBg,
    text: colors.stateWarningText
  }
}

const annotationsFor = (annotationsData) =>
  annotationsData?.map((annotation) => ({
    x: annotation.start_epoch,
    x2: annotation.end_epoch,
    borderColor: colorsByLevel[annotation.level].text,
    fillColor: colorsByLevel[annotation.level].bg,
    label: {
      text: annotation.title,
      borderColor: colorsByLevel[annotation.level].bg,
      style: {
        color: colorsByLevel[annotation.level].text,
        background: colorsByLevel[annotation.level].bg
      }
    }
  }))

export default annotationsFor;
