/**
 * Used to populate a child <select> element when its parent <select> is changed. Depends
 * on an Ajax call.
 *
 * Example:
 *
 *   <select class="nested-select__parent" id="id-of-parent">
 *     <option>...</option>
 *   </select>
 *
 *   <select class="nested-select__child" data-parent="id-of-parent" data-url-template="/parents/__id__/children">
 *   </select>
 *
 * When the parent's value is changed, the value will be substituted for `__id__` in the URL
 * template. Then an Ajax call will be made to the server. The JSON response must be an array
 * of objects with keys `value` and `text`, which will be used to build the <option> elements
 * of the child <select>.
 *
 * Example:
 *
 *   [{
 *     "value": 1,
 *     "text": "Bilbo"
 *   },{
 *     "value": 2,
 *     "text": "Frodo"
 *   }]
 *
 * Repeated instances of `__id__` will be replaced by ancestor values, starting from the
 * furthest ancestor, to allow for additional levels of nesting:
 *
 *   <select ... data-url-template="/grandparents/__id__/parents/__id__/children">
 *
 * If you need to load default values when populating children, two steps are needed:
 *
 * 1 - Add { "default": true } attribute to the affected option at the JSON response
 * 2 - Add "data-with-default" attribute to the affected child dropdown
 *
 * Example:
 *
 *   [{
 *     "value": 1,
 *     "text": "Bilbo"
 *   },{
 *     "value": 2,
 *     "text": "Frodo",
 *     "default": true
 *   }]
 *
 *   <select class="nested-select__child" data-with-default ... >
 *   </select>
 *
 * If you want to pre-select a specific option (for example, when a form submit fails):
 *
 * 1 - Add "data-selected-value" attribute with the selected value.
 *
 * This will supersede the default value
 *
 */

function ancestorValues($child, values = []) {
  const $parent = $(`#${$child.data("parent")}`);

  if ($parent.length) {
    values.unshift($parent.val());
    return ancestorValues($parent, values);
  } else {
    return values;
  }
}

function generateUrl($child) {
  const urlTemplate = $child.data("url-template");
  const values = ancestorValues($child);

  return urlTemplate.replaceAll("__id__", () => values.shift());
}

function updateChild($child) {
  const url = generateUrl($child);
  const valueAttribute = $child.data("value-attribute") || "value";

  $.getJSON(url).done((records) => {
    $child.empty();

    for (const record of records) {
      let option = $("<option>").attr("value", record[valueAttribute]).text(record.text)

      if ($child.data("selected-value")) {
        const values = [$child.data("selected-value")].flat();

        if (values.includes(record[valueAttribute])) {
          option.attr("selected", "selected");
        }
      } else if ($child.data("with-default") && record.default) {
        option.attr("selected", "selected");
      }

      $child.append(option);
    }

    $child.trigger("change");
  });
}

$(document).on("change", ".nested-select__parent", (e) => {
  const $parent = $(e.target);
  const $children = $(`.nested-select__child[data-parent=${$parent.attr("id")}]`);

  $children.each((_, el) => updateChild($(el)));
});
