import RequestPreview from 'utils/request_preview'

class Form {
  constructor (element) {
    this.$element = $(element)
    this.element = this.$element.get(0)
    this.id = this.$element.attr('id').replace(/[\D]/g, '')
    this.url = this.$element.data('sample-url')
    this.requestPreview = new RequestPreview(this.element, { id: this.id, url: this.url })
  }

  create () {
    this.requestPreview.create()
  }
}

document.addEventListener('turbo:load', () => {
  const $formList = $('.edit-experiment-form')

  $.map($formList, (el) => (new Form(el)).create())
})

export default Form
