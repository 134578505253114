$(function() {
  addScrollPositionEventListeners()
})

const addScrollPositionEventListeners = () => {
  $(document).on('brix:widgets:initialized', () => setScrollPositionToAnchoredPortlet())
  $(window).on('hashchange', () => isDashboardShowVisit() && setScrollPositionToAnchoredPortlet())
}

const isDashboardShowVisit = () => $('html').find("body.dashboards.show").length

const setScrollPositionToAnchoredPortlet = () => {
  if(anchoredPortletPresentOnPage()) {
    disableScrollRestorationUntilReload()
    $(document).scrollTop(anchoredPortletScrollTopWithHeaderOffsets())
    triggerAnchorAnimation()
  }
}

const hashTarget = () => {
  const namespacePrefix = "#brix_"
  const hash = window.location.hash.match(/#(brix_)?portlet_\d+/)?.[0]

  if (!hash || hash.startsWith(namespacePrefix)) {
    return hash;
  } else {
    return hash.replace("#", namespacePrefix)
  }
}

const anchoredPortletPresentOnPage = () => $(hashTarget()).length

const disableScrollRestorationUntilReload = () => {
  history.scrollRestoration = "manual";
  $(document).one("turbo:load", () => history.scrollRestoration = "auto")
}

const anchoredPortletScrollTopWithHeaderOffsets = () => {
  const navbarOffset = $(".navbar__container").first().height();
  const actionsMenuOffset = $(".dashboard .actions").first().height();
  const breathingRoomOffset = 10;

  return $(hashTarget()).offset().top - (navbarOffset + actionsMenuOffset + breathingRoomOffset)
}

const triggerAnchorAnimation = () => {
  const anchorClass = "portlet--anchored";

  $(`.${anchorClass}`).removeClass(anchorClass)
  $(hashTarget()).addClass(anchorClass)
}
