import ToggleSelectOptionByPlaybackModes from './toggle_select_options_by_playback_modes';

class VisionVideo {
  static selectors = {
    task: '.task-row'
  }

  constructor (element) {
    this.$element = $(element);
    this.$tasks = this.$element.find(this.constructor.selectors.task);
  }

  create () {
    $.each(this.$tasks, (_index, task) => this.#initTask(task));

    return this;
  }

  #initTask (task) {
    return (new ToggleSelectOptionByPlaybackModes(task)).create();
  }
}

$(document).on('turbo:load', () => {
  const $targets = $('[data-capabilities~="vision-video"]');

  $.map($targets, (el) => (new VisionVideo(el)).create());
});

export default VisionVideo;
