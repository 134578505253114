const updatePosition = (e, ui) => {
  const targetId = $(ui.item).data('videoId');
  videosTable().find('tbody > tr').each( (i, el) => {
    if ($(el).data('videoId') == targetId) {
      $.ajax({
        url: $(el).data('updateUrl'),
        method: 'patch',
        data: {
          video: {
            position: i + 1
          }
        }
      });

      return false;
    }
  });
};

const videosTable = () => $('.videos.index #videos');

$(document).on('turbo:load', () => {
  videosTable().sortable({
    handle: '.drag-handle',
    items: 'tbody > tr',
    stop: updatePosition,
    axis: 'y',
  });
});
