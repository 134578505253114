function assignPosition() {
  $('.proposed-output-fields:visible').each((index, element) => {
    $(element).find('input[name$="[position]"]').val(index + 1)
    $(element).find('p.position').text(index + 1)
  })
}

function hideOriginalElement(element) {
  $(element.parentNode).hide();
  $(element.parentNode).removeClass('d-flex');
}

function showOriginalElement(insertedItem) {
  const mediaId = insertedItem.find('input[name$="[media_id]"]').val()
  $(`#media-id-${mediaId}`).css("display", "")
  $(`#media-id-${mediaId}`).addClass('d-flex')
}

function hideNoOutputsPlaceholder() {
  const noOutputPlaceholder = $(".no-outputs");
  noOutputPlaceholder.addClass("d-none")
}

function showNoOutputsPlaceholder() {
  const noOutputPlaceholder = $(".no-outputs");
  if ($("#proposed-outputs .proposed-output-fields:visible").length == 0)
  {
    noOutputPlaceholder.removeClass("d-none")
  }
}

$(document).on('cocoon:after-insert', '#lpa_example_form', (_e, _insertedItem, originalEvent) => {
  assignPosition();
  hideNoOutputsPlaceholder();
  hideOriginalElement(originalEvent.target)
});

$(document).on('cocoon:before-remove', '#lpa_example_form', (_e, insertedItem, _originalEvent) => {
  showOriginalElement(insertedItem);
})

$(document).on('cocoon:after-remove', '#lpa_example_form',  (_e, _insertedItem, _originalEvent) => {
  assignPosition();
  showNoOutputsPlaceholder();
})
