import ApexCharts from 'apexcharts';
import { merge } from 'lodash';

import VerticalBarChartOptions from 'apex/utils/chart_options/vertical_bar_chart_options';

class SidePreferenceDistributionByReasonChartComponent {
  static defaultOptions() {
    return merge({}, VerticalBarChartOptions.defaultOptions(), {
      plotOptions: {
        bar: {
          columnWidth: '70%',
        }
      },
      xaxis: {
        axisTicks: {
          height: 16
        }
      }
    });
  }

  constructor(element, options) {
    this.element = element;
    this.options = merge(this.constructor.defaultOptions(), options);
    this.chart = new ApexCharts(this.element, this.options);
  }

  render() {
    this.chart.render();
    this._bindDestroyEvent();
  }

  destroy(_e) {
    this.chart.destroy();
  }

  _bindDestroyEvent() {
    document.addEventListener('turbo:before-cache', this.destroy.bind(this));
  }
}

document.addEventListener('turbo:load', () => {
  const chartList = Array.from(document.querySelectorAll('[data-scrapes-side-preference-distribution-by-reason-chart]'));

  chartList.forEach((el) => {
    const options = JSON.parse(el.dataset.scrapesSidePreferenceDistributionByReasonChart || '{}');

    (new SidePreferenceDistributionByReasonChartComponent(el, options)).render();
  });
});

export default SidePreferenceDistributionByReasonChartComponent;
