class ToggleSelectOptionByPlaybackModes {
  static dataAttrNames = {
    option: 'playback-modes-option-for',
    values: 'playback-modes-values'
  }

  constructor (element) {
    this.$element = $(element);
    this.mode = this.#mode();
  }

  create () {
    this.#removeOptionsForOtherModes(this.$element[0]);
    this.$element.on('task:load', this.#bindTaskLoadEvent.bind(this));
    this.$element.on('modal:load', this.#bindModalLoadEvent.bind(this));

    return this;
  }

  #bindTaskLoadEvent (e) {
    e.stopPropagation();
    this.#removeOptionsForOtherModes(e.target);
  }

  #bindModalLoadEvent (e, data) {
    e.stopPropagation();
    this.#removeOptionsForOtherModes(data.modal);
  }

  #removeOptionsForOtherModes (container) {
    $(container)
      .find(`[data-${this.constructor.dataAttrNames.option}]`)
      .not(`[data-${this.constructor.dataAttrNames.option}="${this.mode}"]`)
      .remove();
  }

  #mode () {
    const modes = this.$element
      .find(`[data-${this.constructor.dataAttrNames.values}]`).first()
      .data(`${this.constructor.dataAttrNames.values}`) || [];

    if (modes.some(m => ['stereoscopic', 'immersive'].includes(m.toLowerCase()))) {
      return '3D';
    } else {
      return '2D';
    }
  }
}

export default ToggleSelectOptionByPlaybackModes;
