const intervals = [];

$(document).on("turbo:load", () => {
  $("[data-ratio-scraped]").each((_, el) => {
    if ($(el).data("ratio-scraped") < 1) {
      const interval = setInterval(() => $.get($(el).data("scrape-url")), 1000);
      intervals.push(interval);
    }
  });
});

$(document).on("turbo:before-cache", () => {
  intervals.forEach(clearInterval);
  intervals.length = 0;
});
