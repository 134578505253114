import { Controller } from '@hotwired/stimulus'
import { TempusDominus } from '@eonasdan/tempus-dominus'
import { merge } from 'lodash'

export default class extends Controller {
  static targets = ['input', 'icon']
  static values = {
    options: Object,
    disabled: { type: Boolean, default: false }
  }

  static defaultOptions = {
    localization: {
      startOfTheWeek: 1,
      format: 'yyyy-MM-dd h:mm T'
    },
    display: {
      theme: 'light',
      icons: {
        time: 'glyphicon glyphicon-time',
        date: 'glyphicon glyphicon-calendar',
        up: 'glyphicon glyphicon-menu-up',
        down: 'glyphicon glyphicon-menu-down',
        previous: 'glyphicon glyphicon-menu-left',
        next: 'glyphicon glyphicon-menu-right',
        today: 'glyphicon glyphicon-calendar',
        clear: 'glyphicon glyphicon-trash',
        close: 'glyphicon glyphicon-remove'
      }
    }
  }

  connect() {
    this.#connectTargets()

    this.picker = new TempusDominus(
      this.element,
      merge({}, this.constructor.defaultOptions, this.optionsValue)
    )
  }

  disconnect() {
    this.picker.dispose()
  }

  #connectTargets() {
    if (this.disabledValue) return

    const id = `td-${crypto.getRandomValues(new Uint32Array(1))}`

    this.element.id = id

    $(this.element).attr({
      'data-td-target-input': 'nearest',
      'data-td-target-toggle': 'nearest'
    })

    if (this.hasInputTarget) {
      $(this.inputTarget).attr('data-td-target', `#${id}`)
    }

    if (this.hasIconTarget) {
      $(this.iconTarget).attr({
        'data-td-target': `#${id}`,
        'data-td-toggle': 'datetimepicker'
      })
    }
  }
}
