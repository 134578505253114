class PollIterationPercentCompleted {
  constructor (element) {
    this.$element = $(element)
    this.url = this.$element.data('endpoint-diff-iteration-url')
  }

  startPolling () {
    this.timer = setInterval(this._poll.bind(this), 15000)
    $(document).one('turbo:before-cache', this._clearTimer.bind(this))
  }

  _clearTimer () {
    clearInterval(this.timer)
  }

  _poll () {
    $.getJSON(this.url).done(({ data: { percentCompleted, complete } }) => {
      if (complete) this._clearTimer()
      this.$element.find('[data-attr="percent-completed"]').text(percentCompleted);
    })
  }
}

document.addEventListener('turbo:load', () => {
  $('[data-complete=false][data-endpoint-diff-iteration-url]').each(function() {
    new PollIterationPercentCompleted(this).startPolling();
  });
})

export default PollIterationPercentCompleted;
