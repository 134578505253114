class PollPercentCompleted {
  constructor (element) {
    this.$element = $(element)
    this.url = this.$element.data('endpoint-diff-url')
  }

  startPolling () {
    this.timer = setInterval(this._poll.bind(this), 3000)
    $(document).one('turbo:before-cache', this._clearTimer.bind(this))
  }

  _clearTimer () {
    clearInterval(this.timer)
  }

  _poll () {
    this.$element.find('[data-bs-toggle="tooltip"]').tooltip('dispose')

    $.getJSON(this.url).done(({ data: {
      inputsCount, diffsCount, weightedPwdiffScore, pwdiffScore, weightedPercentDiff,
      percentDiff, percentCompleted, complete, runtime, avgResponseTime
    } }) => {
      if (complete) this._clearTimer()

      this.$element.find('[data-attr="inputs-count"]').text(inputsCount)
      this.$element.find('[data-attr="diffs-count"]').text(diffsCount)
      this.$element.find('[data-attr="weighted-pwdiff-score"]').text(weightedPwdiffScore)
      this.$element.find('[data-attr="pwdiff-score"]').text(pwdiffScore)
      this.$element.find('[data-attr="weighted-percent-diff"]').text(weightedPercentDiff)
      this.$element.find('[data-attr="percent-diff"]').text(percentDiff)
      this.$element.find('[data-attr="percent-completed"]').html(percentCompleted)
      this.$element.find('[data-attr="runtime"]').html(runtime)
      this.$element.find('[data-attr="avg-response-time"]').html(avgResponseTime)
    })
  }
}

document.addEventListener('turbo:load', () => {
  $('[data-complete=false][data-endpoint-diff-url]').each(function() {
    new PollPercentCompleted(this).startPolling();
  });
})

export default PollPercentCompleted;
