// When an eval has data-capabilities=vision-venus-links-override
// all venus links (which have a data-search-link-type='Venus' attribute)
// will be rewritten to set the platform to realitydevice
class RewriteVenusLinksPlatform {
  constructor(link) {
    this.$link = link;
  };

  call() {
    const url = new URL(this.$link.href);
    url.searchParams.set('platform', 'realitydevice');
    this.$link.href = url;
  };
};

$(document).on('turbo:load', () => {
  $('[data-capabilities~="vision-venus-links-override"]  a[data-search-link-type="Venus"]').each(function () { new RewriteVenusLinksPlatform(this).call(); })
});

export default RewriteVenusLinksPlatform;
