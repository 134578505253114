import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="form--checkbox-select-all"
export default class extends Controller {
  static targets = [ 'parent', 'child' ]

  connect() {
    this.toggleParent()
  }

  toggleChildren() {
    if (this.parentTarget.checked) {
      this.childTargets.map(x => x.checked = true)
    } else {
      this.childTargets.map(x => x.checked = false)
    }
  }

  toggleParent() {
    if (this.childTargets.map(x => x.checked).includes(false)) {
      this.parentTarget.checked = false
    } else {
      this.parentTarget.checked = true
    }
  }
}
