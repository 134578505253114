import * as Sentry from "@sentry/browser"
import makeFetchTransportWithCSRFToken from 'utils/sentry/make_fetch_transport_with_csrf_token'

Sentry.init({
  autoSessionTracking: false,
  dsn: process.env.SENTRY_JS_DSN,
  release: document.querySelector('meta[name="release"]').content,
  transport: makeFetchTransportWithCSRFToken,
  tunnel: '/sentry',
  allowUrls: [
    /https:\/\/baseline(\.eval)?\.apple\.com/,
  ],
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "The play() request was interrupted by a call to pause()",
    /MtPopUpList/,
    /AbortError/,
    /Failed to fetch/
  ],
  sendClientReports: false
})
