import { merge } from "lodash";

import styles from "utils/colors";
import BaseOptions from "./base_options";

const formatter = (value) => "$" + Number.parseFloat(value).toFixed(2);

class BoxPlotOptions extends BaseOptions {
  static defaultOptions() {
    return merge(super.defaultOptions(), {
      chart: {
        type: "boxPlot"
      },
      title: {
        align: "center",
      },
      subtitle: {
        align: "center",
        margin: 18
      },
      xaxis: {
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        labels: {
          formatter: formatter
        }
      }
    });
  }

  constructor(chartData) {
    super(chartData);

    this.dataDependentOptions = this.setDataDependentOptions();
  }

  setDataDependentOptions = () => {
    return {
      title: {
        text: this.chartData.meta.title
      },
      subtitle: {
        text: this.subtitle(this.chartData.meta.significance)
      },
      series: [{
        data: this.chartData.percentiles
      }],
      tooltip: {
        custom: this.tooltipFormatter
      },
      plotOptions: {
        boxPlot: {
          colors: {
            upper: this.color(this.chartData.meta.significance),
            lower: this.color(this.chartData.meta.significance)
          }
        }
      }
    };
  }

  color = (significance) => {
    if (this.isPresentationMode()) {
      if (significance.direction == "positive") {
        return "#009c22";
      } else if (significance.direction == "negative") {
        return "#ff453a";
      } else {
        return "#d6d6d6";
      }
    } else {
      if (significance.direction == "positive") {
        return "#007d1b";
      } else if (significance.direction == "negative") {
        return "#ff3b30";
      } else {
        return "#d6d6d6";
      }
    }
  }

  subtitle = (significance) => `${significance.outcome} (p = ${significance.p_value})`

  tooltipFormatter = ({ seriesIndex, dataPointIndex, w }) => {
    const percentiles = {
      p95: w.globals.seriesCandleC[seriesIndex][dataPointIndex],
      p75: w.globals.seriesCandleL[seriesIndex][dataPointIndex],
      p25: w.globals.seriesCandleH[seriesIndex][dataPointIndex],
      p50: w.globals.seriesCandleM[seriesIndex][dataPointIndex],
      p05: w.globals.seriesCandleO[seriesIndex][dataPointIndex],
    };

    const titleContainer = $("<div>")
      .addClass("apexcharts-tooltip-title")
      .text(w.globals.categoryLabels[dataPointIndex]);

    const percentilesContainer = $("<div>");

    for (const label in percentiles) {
      const value = formatter(percentiles[label]);

      percentilesContainer.append(
        $("<div>")
          .addClass("apexcharts-tooltip-series-group d-flex justify-content-between")
          .append($("<strong>").text(label))
          .append($("<span>").text(value).addClass("value text-end ms-3"))
      )
    }

    return $("<div>")
      .addClass("apexcharts-tooltip-box apexcharts-tooltip-boxPlot")
      .append(titleContainer)
      .append(percentilesContainer)
      .html();
  }

  get presentationOptions() {
    return {
      theme: {
        mode: "dark"
      },
      chart: {
        background: styles.presentationBackgroundColor
      },
      stroke: {
        colors: ["#dee2e6"]
      },
      title: {
        style: {
          color: styles.white
        }
      },
      grid: {
        borderColor: styles.presentationBorderColor
      }
    };
  }
}

export default BoxPlotOptions;
