import { getElementDropdownParent } from "../utils/select2";

$(document).on('turbo:load', () => {
  $('.pool_tasks.search #search-form select').each((_index, el) => {
    $(el).select2({ width: '100%', dropdownParent: getElementDropdownParent(el) });
  });

  $('.pool_tasks.search #search-form input[type="submit"]')
    .removeClass('visually-hidden')
    .addClass('btn btn-primary');
});
