// Turbo Streams doesn't trigger an event for after rendering
// Here we emulate it by hijacking before-stream-render and creating the
// event after calling render()
document.addEventListener("turbo:before-stream-render", (event) => {
  const afterRenderEvent = new CustomEvent("turbo:after-stream-render", event)
  const originalRender = event.detail.render

  event.detail.render = function (streamElement) {
    originalRender(streamElement)
    document.dispatchEvent(afterRenderEvent);
  }
})
