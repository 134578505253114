import { format } from "d3";

import styles from "utils/colors";

function createChart($el, values) {
  const categories = values.map(e => e.label);
  const series = [{
    data: values.map(e => e.value)
  }];

  const options = {
    series: series,
    colors: [styles.chartBlue],
    chart: {
      type: "bar",
      height: "100%",
      animations: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    title: {
      text: "Inputs per Standard Deviation from Mean Difference",
      align: "center"
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      formatter: format(","),
      style: {
        colors: [styles.black]
      }
    },
    grid: {
      show: false
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top"
        }
      }
    },
    xaxis: {
      categories: categories
    },
    yaxis: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  };

  const chart = new ApexCharts($el.get(0), options);

  chart.render();
  $(document).one("turbo:before-cache", () => chart.destroy());
}

function loadDifferencesByBucket() {
  const $el = $("#differences-by-bucket");

  if ($el.length) {
    $.getJSON($el.data("url"), (values) => createChart($el.find(".chart"), values));
  }
}

$(document).on("turbo:load", loadDifferencesByBucket);
