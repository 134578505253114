import { ClickstreamProcessor, WebDelegates } from "@amp-metrics/mt-metricskit"

const topic = "xp_amp_baseline_cs"

class Reporter {
  constructor() {
    this.initializeDelegates()
    this.initializeProcessor()
    this.initializeEventHandlers()
    this.initializeLastCall()
    this.initializeLogger()
    this.initializeDebugSource()

    this.processor.init()
  }

  recordEvent(eventType, data = {}) {
    const { pageId, pageType, pageContext } = this.instrumentationData()
    const metricsData = this.processor.eventHandlers[eventType].metricsData(pageId, pageType, pageContext, data || {})
    metricsData.recordEvent(topic)
  }

  initializeDelegates() {
    this.delegates = new WebDelegates(topic)
  }

  initializeProcessor() {
    this.processor = new ClickstreamProcessor(this.delegates)
  }

  // automatically add default metrics data to every event
  initializeEventHandlers() {
    const existingKnownFields = this.processor.eventHandlers.base.knownFields()

    this.processor.eventHandlers.base.setDelegate({
      knownFields: () => existingKnownFields.concat(["baselineUserId"])
    })

    this.processor.eventHandlers.base.baselineUserId = () => this.instrumentationData("baselineUserId")
    this.processor.eventHandlers.base.app = () => "com.apple.amp.eval.baseline"
    this.processor.eventHandlers.base.appVersion = () => this.instrumentationData("appVersion")
  }

  initializeLastCall() {
    $(window).on("beforeunload", () => {
      $(document).trigger("instrument:last-call");

      this.processor.system.eventRecorder.flushUnreportedEvents(true);
    });
  }

  initializeLogger() {
    const logLevel = process.env.BUILD_ENV === "production" ? "error" : "info"
    this.processor.system.logger.setLevel(logLevel)
  }

  initializeDebugSource() {
    if (process.env.BUILD_ENV === "development" || process.env.BUILD_ENV === "uat") {
      this.delegates.config.setDebugSource({
        configBaseurl: "https://xp-qa.apple.com/config/1/report",
        metricsUrl: "https://xp-qa.apple.com/report"
      })
    }
  }

  instrumentationData(attribute) {
    const baseData = $(document.body).data("instrument-base") || {}
    return attribute ? baseData[attribute] : baseData
  }
}

export const reporter = new Reporter()
