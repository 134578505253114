import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="ab--experiment-storefront"
export default class extends Controller {
  static targets = [
    'allStorefrontsInput',
    'storefrontsContainer',
    'storefrontInput',
    'bucketConflictsContainer',
    'refreshIcon',
    'conflictIcon'
  ]

  static values = { bucketConflictsPath: String }

  connect() {
    this.toggleStorefrontsTable()
    this.fetchConflicts()
  }

  toggleStorefrontsTable() {
    const allStorefronts = this.allStorefrontsInputTarget.checked

    this.storefrontsContainerTarget.classList.toggle('d-none', allStorefronts)

    for (const input of this.storefrontInputTargets) {
      input.disabled = allStorefronts
    }
  }

  async fetchConflicts() {
    for (const icon of this.conflictIconTargets) {
      icon.classList.add("d-none")
    }

    if (this.hasRefreshIconTarget) {
      this.refreshIconTarget.classList.remove("d-none")
    }

    const data = new FormData(this.element)
    const url = this.bucketConflictsPathValue

    const request = await get(url, {
      responseKind: "html",
      query: data
    })

    this.bucketConflictsContainerTarget.innerHTML = await request.html

    this.refreshIconTarget.classList.add("d-none")
  }
}
