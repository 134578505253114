import { merge } from 'lodash';
import styles from 'utils/colors';

class BaseOptions {
  static defaultOptions() {
    return {
      chart: {
        animations: { enabled: false },
        toolbar: { show: false },
      },
      legend: {
        position: 'top',
        markers: { radius: '50' }
      },
      title: {
        align: 'center',
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          color: styles.grayDark
        }
      }
    };
  }

  get presentationOptions() {
    return {};
  }

  constructor(chartData) {
    this.chartData = chartData;
    this.dataDependentOptions = {};
  }

  build = () => merge(
    {},
    this.constructor.defaultOptions(),
    this.chartData,
    this.dataDependentOptions,
    this.isPresentationMode() ? this.presentationOptions : {}
  )

  isPresentationMode = () => document.body.classList.contains("presentation")
}

export default BaseOptions;
