function addMeasureFields(association, fields) {
  const newId = (new Date()).getTime();
  const regexp = new RegExp(`new_${association}`, "g");

  $("#tabular-report-measures-fields .add-button").before(fields.replace(regexp, newId));
}

$(document).on("click", "#tabular-report-measures-fields .new-measure", (e) => {
  const data = $(e.currentTarget).data();

  e.preventDefault();
  addMeasureFields(data.association, data.fields);
});

function removeMeasureFields($link) {
  $link.closest(".measure").find(".destroy-field").val(true);
  $link.closest("li").hide();
}

$(document).on("click", "#tabular-report-measures-fields .remove-icon", (e) => {
  e.preventDefault();
  removeMeasureFields($(e.currentTarget));
});
