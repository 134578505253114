function checkReportProgress() {
  const $el = $(".report-progress");

  if ($el.length) {
    $.getJSON($el.data("url")).done((status) => {
      if (status.running) {
        setTimeout(checkReportProgress, 1000);
      } else {
        Turbo.visit(location.toString());
      }
    });
  }
}

$(document).on("turbo:load", checkReportProgress);
