import { Controller } from '@hotwired/stimulus'
import fuzzyMatch from '../../utils/fuzzy_match'
import { sortBy, merge } from 'lodash'

export default class extends Controller {
  static stateTemplate = (state, dataAttribute) => {
    const template = $($(state.element).data(dataAttribute))

    return state.id && template.length ? template : state.text
  }

  static defaultOptions = {
    width: '100%',
    templateResult: (state) => this.stateTemplate(state, 'template-result'),
    templateSelection: (state) => this.stateTemplate(state, 'template-selection')
  }

  static values = { options: Object }

  connect() {
    $(this.element)
      .select2(this.#options()).on('select2:select select2:unselect', function (event) {
        event.target.dispatchEvent(new Event('change'))
      })
  }

  #options() {
    let options = {
      ...this.constructor.defaultOptions,
      ...this.optionsValue,
      dropdownParent: this.#getElementDropdownParent()
    }

    if (options?.ajax) {
      options = merge({}, {
        placeholder: '',
        allowClear: true,
        ajax: {
          delay: 250,
          dataType: 'json',
          processResults: function (data, {term} ) {
            const results = sortBy(data.results, function(el) { return fuzzyMatch(el.text, term || "")[1] * -1 })

            return { results }
          }
        }
      }, options)
    }

    return options
  }

  #getElementDropdownParent() {
    const dropdownParent = this.element.closest(this.optionsValue.dropdownParent) || document.body
    const form = this.element.closest('form')

    return dropdownParent.contains(form) ? form : dropdownParent
  }
}
