import { Controller } from "@hotwired/stimulus"

const COPY_NEXT = "Next"
const COPY_SAVE = "Save"

// Connects to data-controller="ab--confirmable-modal"
export default class extends Controller {
  static targets = ["confirmableForm", "confirmationScreen", "saveButton"]
  static outlets = ["ab--significant-change-value"]

  static values = {
    confirmed: { type: Boolean, default: true }
  }

  formSubmit(e) {
    if (this.hasConfirmationScreenTarget) {
      if (!this.confirmedValue) {
        e.preventDefault()
        e.stopImmediatePropagation()
        this.confirmationScreen()
      }
    }
  }

  confirmedSubmit() {
    this.confirmedValue = true
  }

  revertSubmit() {
    this.confirmableFormTarget.classList.remove("d-none")
    this.confirmationScreenTarget.classList.add("d-none")
  }

  confirmationScreen() {
    this.confirmableFormTarget.classList.add("d-none")
    this.confirmationScreenTarget.classList.remove("d-none")
  }

  significantValueChanged() {
    this.confirmedValue = !this.hasSignificantChange()
  }

  confirmedValueChanged() {
    if (!this.shouldUpdateCopy()) { return }

    this.saveButtonTarget.value = this.confirmedValue ? COPY_SAVE : COPY_NEXT
  }

  hasSignificantChange() {
    return this.abSignificantChangeValueOutlets.some(c => c.hasChanged())
  }

  shouldUpdateCopy() {
    return this.hasSaveButtonTarget && this.hasConfirmationScreenTarget
  }
}
