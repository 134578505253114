function addDimensionFields(association, fields) {
  const newId = (new Date()).getTime();
  const regexp = new RegExp(`new_${association}`, "g");

  $("#tabular-report-dimensions-fields .add-button").before(fields.replace(regexp, newId));
}

$(document).on("click", "#tabular-report-dimensions-fields .new-dimension", (e) => {
  const data = $(e.currentTarget).data();

  e.preventDefault();
  addDimensionFields(data.association, data.fields);
});

function removeDimensionFields($link) {
  $link.closest(".dimension").find(".destroy-field").val(true);
  $link.closest("li").hide();
}

$(document).on("click", "#tabular-report-dimensions-fields .remove-icon", (e) => {
  e.preventDefault();
  removeDimensionFields($(e.currentTarget));
});
