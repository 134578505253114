import Rails from '@rails/ujs';

$(document).on('turbo:load', function() {
  if ($('#metrics-simulator #metrics_simulator_metric').val()) {
    Rails.fire(document.getElementById('metrics-simulator'), 'submit');
  }
});

$(document).on('ajax:before', '#metrics-simulator', (e) => {
  $('#metrics-simulator__getting-started').hide();
});

$(document).on('ajax:success', '#metrics-simulator', (e) => {
  const [document, status, xhr] = e.detail;

  $('#metrics-simulator__score-cards').prepend(xhr.response);
  $('[data-bs-toggle="tooltip"]').tooltip()
  $('.metrics-simulator__score-card--show').removeClass('metrics-simulator__score-card--show')

  window.requestAnimationFrame(function() {
    $('.metrics-simulator__score-card').addClass('metrics-simulator__score-card--show');
  });
});

$(document).on('click', '.metrics-simulator__close', (e) => {
  const target = $(e.target).closest('.metrics-simulator__score-card')
  target.one('transitionend', () => target.remove());
  target.addClass('metrics-simulator__score-card--hide');
});
