import Component from '../../store/lib/component.js';
import formatterFor from 'apex/utils/formatter_for';

const RESULTS_TITLE_SELECTOR   = '#elc__results-title';
const RESULTS_BODY_SELECTOR    = '#elc__results-body';
const RESULTS_DAYS_SELECTOR    = '#elc__results-days';
const RESULTS_SAMPLES_SELECTOR = '#elc__results-samples';

export default class Results extends Component {
  constructor(props = {}) {
    super(props);

    this.$resultTitle = $(RESULTS_TITLE_SELECTOR);
    this.$resultBody = $(RESULTS_BODY_SELECTOR);
    this.$resultDays = $(RESULTS_DAYS_SELECTOR);
    this.$resultSamples = $(RESULTS_SAMPLES_SELECTOR);

    props.store.events.subscribe('updateResults', () => this.updateResults());
    props.store.events.subscribe('cleanResults', () => this.cleanResults());
    props.store.events.subscribe('resetResults', () => this.resetResults());
    props.store.events.subscribe('processErrors', (data) => this.processErrors(data));
  }

  updateResults() {
    this.cleanResults();

    if (this.store.state.measure === null) {
      this.$resultBody.html('No Measure Selected');
      return;
    }

    if (this.store.state.denominator === null || this.store.state.denominator === 0) {
      this.$resultBody.html('Missing data for this Measure');
      return;
    }

    if (this.store.state.calculatedDays == null) {
      this.resetResults();
      return;
    }

    const calculatedDays = this.store.state.calculatedDays;
    const recommendedDays = Math.max(7, Math.ceil(calculatedDays));
    const formattedRecommendedDays = formatterFor('integer')(recommendedDays);
    const formattedCalculatedDays = formatterFor('float')(calculatedDays);
    const formattedObservations = formatterFor('integer')(this.store.state.calculatedSamples);

    this.$resultTitle.html('To reach statistical significance, your experiment should run for');
    this.$resultDays.html(`${formattedRecommendedDays} days recommended`);
    this.$resultSamples.html(`${formattedCalculatedDays} days required to reach ${formattedObservations} observations per treatment`);
  }

  resetResults() {
    this.cleanResults();
    this.$resultBody.html('Please complete this form to calculate the recommended duration for your experiment');
  }

  cleanResults() {
    this.$resultTitle.html('');
    this.$resultBody.html('');
    this.$resultDays.html('');
    this.$resultSamples.html('');
  }

  processErrors = (response) => {
    if (response.status != 500) {
      if (response.responseJSON) {
        this.displayErrors(response.responseJSON.errors.base);
      }
      this.store.events.publish('showNoDataMessage');
    } else {
      this.displayErrors(['Server error. Please try again with different parameters']);
    }
  };

  displayErrors(data) {
    this.cleanResults();
    this.store.events.publish('refreshSamplesData', { forcedValue: ' ' });
    this.$resultBody.html(data.join(', '));
  }

  initialize() {
    this.resetResults();
  }
}
