function addFilterFields(association, fields) {
  const newId = (new Date()).getTime();
  const regexp = new RegExp(`new_${association}`, "g");

  $("#tabular-report-filters-fields .add-button").before(fields.replace(regexp, newId));
}

$(document).on("click", "#tabular-report-filters-fields .new-filter", (e) => {
  const data = $(e.currentTarget).data();

  e.preventDefault();
  addFilterFields(data.association, data.fields);
});

function removeFilterFields($link) {
  $link.closest(".filter")
    .find(".destroy-field").val(true).end()
    .find(".modal").remove();

  $link.closest("li").hide();
}

$(document).on("click", "#tabular-report-filters-fields .remove-icon", (e) => {
  e.preventDefault();
  removeFilterFields($(e.currentTarget));
});

$(document).on("change", "#tabular-report-filters-fields .filter-key", (e) => {
  const $select = $(e.currentTarget);
  const url = $select.data("url");

  $.get(url, { key: $select.val() }, (html) => {
    $select.closest(".filter").replaceWith(html);
  });
});

$(document).on("click", ".filter-modal .save-filter-button", (e) => {
  const $parent = $(e.currentTarget).closest(".filter-modal");
  const $select = $parent.find(".filter-item-value:last");
  const value = $select.val();
  const url = $parent.data("submit-url");

  const childIndex = $parent
    .closest(".filter")
    .find(".filter-key")
    .attr("name")
    .match(/tabular_report\[filters_attributes\]\[(\d+)\]/)[1];

  $.get(url, { value: value, child_index: childIndex }, (html) => {
    $parent.closest(".filter-value").find(".filter-values").append(html);
    $select.val('').trigger('change')
  });
});

$(document).on("change", ".filter-modal .filter-nested-select", (e) => {
  const $select = $(e.currentTarget);
  const url = $select.data("url");

  $.get(url, { parent_id: $select.val() }, (html) => {
    $($select.data("target")).html(html);
  });
});

$(document).on("change", ".filter-item-value-container .filter-item-value", (e) => {
  const $button = $(e.currentTarget).closest(".filter-modal").find(".save-filter-button");

  if ($(e.currentTarget).val()) {
    $button.removeAttr("disabled");
  } else {
    $button.attr("disabled", "disabled");
  }
});

$(document).on("click", ".remove-filter-item", (e) => {
  e.preventDefault();
  $(e.currentTarget).closest(".list-group-item").remove();
});
