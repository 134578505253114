import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ab--turbo-modal"
export default class extends Controller {
  static targets = ['content']

  contentTargetConnected() {
    this.openModal()
  }

  contentTargetDisconnected() {
    this.closeModal()
  }

  openModal() {
    $(this.element).modal('show')
  }

  closeModal() {
    $(this.element).modal('hide')
  }
}
