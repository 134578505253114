import RequestPreview from 'utils/request_preview'

class Form {
  constructor (element) {
    this.$element = $(element)
    this.id = this.$element.attr('id').replace(/[\D]/g, '')
    this.url = this.$element.data('preview-url')
    this.requestPreviews = this._initRequestPreviews(this.$element.find('[data-request-preview]'))
    this.$configurationPreviewTrigger = this.$element.find('[data-toggle="configuration-preview"]')
  }

  create () {
    $.map(this.requestPreviews, (instance) => instance.create())
    this.$element.on('change', ':input', this._onInputChangeEvent.bind(this))
    this.$configurationPreviewTrigger.on('click', this._onPreviewClickEvent.bind(this))
  }

  _onInputChangeEvent (_e) {
    this._clearConfigurationPreview()
  }

  _clearConfigurationPreview () {
    this.$configurationPreviewTrigger
      .each((_index, el) => $($(el).data('target')).empty())
  }

  _onPreviewClickEvent (e) {
    const $trigger = $(e.target)
    const payload = this.$element.find(':not(input[name=_method])').serialize()
    const url = $trigger.data('url')
    const $target = $($trigger.data('target'))

    e.preventDefault()
    e.stopPropagation()

    $target
      .html($("<span>")
      .addClass("me-2 glyphicon glyphicon-refresh spinning"))
      .append('Loading...')

    $.post(url, payload)
      .done((data) => {
        $target.html(data)
        $target.trigger('curl_snippet:init')
      })
      .fail(() => $target.html('There is a problem making the requests. Please check the configuration and try again.'))
  }

  _initRequestPreviews ($elements) {
    return $.map($elements, (el) => new RequestPreview(el, { id: this.id, url: this.url }))
  }
}

document.addEventListener('turbo:load', () => {
  const $formList = $('[data-endpoint-diff-configuration-form]')

  $.map($formList, (el) => (new Form(el)).create())
})

export default Form
